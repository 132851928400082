import SmartFutureLogo from './smart-future-logo.png';
import SmartFutureLogoWebp from './smart-future-logo.webp';
import OneFallLogo from './one-fall-logo.png';
import OneFallLogoWebp from './one-fall-logo.webp';
import MainAcademyLogo from './main-academy-logo.png';
import MainAcademyLogoWebp from './main-academy-logo.webp';
import QaLightLogo from './qalight-logo.png';
import QaLightLogoWebp from './qalight-logo.webp';

export const partnersData = [
  {
    name: 'Charitable School "Smart Future"',
    link: 'http://smartfuture.org.ua/eng',
    logos: {
      png: SmartFutureLogo,
      webp: SmartFutureLogoWebp,
    }
  },
  {
    name: 'One Fall',
    link: 'https://solo.to/onefallma',
    logos: {
      png: OneFallLogo,
      webp: OneFallLogoWebp,
    }
  },
  {
    name: 'Main Academy',
    link: 'https://mainacademy.ua/',
    logos: {
      png: MainAcademyLogo,
      webp: MainAcademyLogoWebp,
    }
  },
  {
    name: 'QALight',
    link: 'https://qalight.ua/',
    logos: {
      png: QaLightLogo,
      webp: QaLightLogoWebp,
    }
  },
];
