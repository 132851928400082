import HeadlineDesktopImage from './about-headline-desktop.jpg';
import HeadlineDesktopImageWebp from './about-headline-desktop.webp';
import HeadlineTabletImage from './about-headline-tablet.jpg';
import HeadlineTabletImageWebp from './about-headline-tablet.webp';
import HeadlineMobileImage from './about-headline-mobile.jpg';
import HeadlineMobileImageWebp from './about-headline-mobile.webp';

export const aboutData = {
  title: 'About',
  subtitle: 'We support Ukrainian youth in tech education. Join us to empower and help!',
  headline_images: {
    desktop: HeadlineDesktopImage,
    desktopWebp: HeadlineDesktopImageWebp,
    tablet: HeadlineTabletImage,
    tabletWebp: HeadlineTabletImageWebp,
    mobile: HeadlineMobileImage,
    mobileWebp: HeadlineMobileImageWebp,
  },
  principles: [
    {
      heading: 'Help',
      paragraphs: [
        'Through our fundraising efforts, we aim to bridge the gap in access to educational resources and technology. Our financial contributions make a significant impact, enabling students to excel in their studies and pursue their dreams. We\'re gathering donations to acquire the essential equipment, offer thorough training, and establish evacuation procedures.',
        'Together, we can empower the youth of Ukraine, shape a brighter future, and make a difference through help.',
      ]
    },
    {
      heading: 'Support',
      paragraphs: [
        'We understand the harsh realities faced by families who are forced to evacuate their homes due to the war in Ukraine. In such challenging times, we step in to offer assistance. We help facilitate the evacuation process, provide temporary shelter, and offer guidance to those affected.',
        'By providing a supportive environment, we aim to minimize the disruption to the education of our students, ensuring that they can continue their studies and pursue their aspirations.',
      ]
    },
    {
      heading: 'Mentoring',
      paragraphs: [
        'Our mentorship programs, training sessions, and workshops connect talented young minds with experienced professionals from the IT industry. These mentors generously dedicate their time, expertise, and guidance to help shape the future generation of IT professionals in Ukraine.',
        'By sharing their knowledge and providing personalized mentorship, our mentors instill confidence and inspire students to unlock their full potential.',
      ]
    }
  ],
  quotes: [
    {
      text: 'I have met amazing and diverse individuals through this project who share experiences, unite for common goals, and transform each other\'s lives in unexpected ways.',
      author: 'Nazar Pauk, Palianytsia',
    },
    {
      text: 'I have a strong belief in the transformative power of education to create a better, safer, and happier world, particularly for those who are in desperate need of it.',
      author: 'Dmytro Palii, Blizzard Entertainment',
    },
    {
      text: 'Through lines of code and pixels brought to life, this project sculpted worlds within screens and reshaped the contours of my aspirations and ingenuity.',
      author: 'Vlad Kamyshenko, Palianytsia',
    },
    {
      text: 'Joining the Palianytsia Foundation changed my life forever. It has helped me understand what I truly want to do in life. Being a part of this team is incredibly motivating. I am driven to work hard, continue learning, reach new heights, and fulfill my dreams. Nothing can compare to the happiness I feel as a member of the Palianytsia Foundation team!',
      author: 'Artem Chukarin, Palianytsia',
    },
    {
      text: 'I feel a strong sense of pride seeing so many young minds come together; empowered by creative expression to accomplish their goals, and help paint a better world.',
      author: 'Jean Romero, Proletariat Inc',
    },
    {
      text: 'This project has a positive impact on me, boosting my motivation, passion, and sense of fulfillment. It also provides opportunities for personal and professional growth through skill development and learning.',
      author: 'Sergij Proskurnin, Palianytsia',
    },
    {
      text: 'This project has been a tremendous growth opportunity for me. It allowed me to hone my skills and learn new management strategies. Witnessing our team collaborate to craft captivating gaming realms filled with fantastic narratives inspires me daily. I\'m grateful for the opportunity to lead this project and be a part of such a talented team!',
      author: 'Mariia Ivanchenko, Palianytsia',
    },
    {
      text: 'This project has truly fueled my drive to further develop my abilities in 3D modeling and drawing. I feel a deep sense of gratitude for the opportunity to learn and collaborate with the art department, and it\'s inspiring me to keep pushing my creative boundaries.',
      author: 'Ivan Tsyvinskiy, Palianytsia',
    },
    {
      text: 'Palianytsia Foundation offers a unique opportunity for young developers to learn new skills and improve their talents. It has been an honor mentoring these dedicated students as they learn about the tech industry and develop their abilities.',
      author: 'Becca Gray, Blizzard Entertainment',
    },
    {
      text: 'This project profoundly impacted me, highlighting the importance of teamwork. Through collaboration, we achieved more than we could ever have individually. Challenges became a chance to grow, fostering a lasting passion for learning. This experience completely reshaped me, igniting a desire for team projects and personal growth.',
      author: 'Nazarii Kozachenko, Palianytsia',
    },
    {
      text: 'This project has been a big adventure that changed my life in ways I never thought of. I met fantastic people, learned new things, and found a passion that will serve me well in the future. I grew as a team member and individual. This experience has given me a sense of fulfillment and purpose, which brings me happiness.',
      author: 'Sofiia Kryzhanovska, Palianytsia',
    },
    {
      text: 'This project gave me a clear vision of my future and positively changed various aspects of my life. One notable example is the enhancement of my discipline and planning abilities. This project also played a pivotal role in channeling my knowledge and skills toward the right path, offering a sense of structure, reliability, and a boost in self-confidence.',
      author: 'Platon Todorashko, Palianytsia',
    },
    {
      text: 'Having previously taught in art and music spaces, I couldn\'t be happier to be working with these incredible students as they develop and hone strong skillsets in tech.',
      author: 'Christopher Chacko, Proletariat Inc.',
    },
    {
      text: 'This project gives me a chance to grow, learn, and hone my skills. Each challenge encountered is an invitation to transcend my own limits, push the boundaries of what I thought possible, and discover new dimensions of my artistic prowess.',
      author: 'Vasylyna Tsyvinska, Palianytsia',
    },
  ]
};
