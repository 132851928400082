const footerSocialLinks = [
  {
    text: 'LinkedIn',
    href: 'https://www.linkedin.com/company/p-foundation',
  },
  {
    text: 'YouTube',
    href: 'https://www.youtube.com/@p-foundation',
  },
  {
    text: 'Facebook',
    href: 'https://www.facebook.com/PalianytsiaFoundation',
  },
  {
    text: 'Buy Me a Coffee',
    href: 'https://www.buymeacoffee.com/',
  },
];

export default footerSocialLinks;
